export enum EquipmentTypeEuropean {
  // resources keys
  A = 'ALL_TYPES',
  AL = 'ALL_TYPES',
  ALL = 'ALL_TYPES',
  B = 'BULK',
  BO = 'BULK',
  V = 'BOX', // Europe specific
  VO = 'BOX', // Europe specific
  F = 'FLAT_BED',
  FO = 'FLAT_BED',
  L = 'LTL',
  LO = 'LTL',
  R = 'REFRIGERATED', // Europe specific
  RO = 'REFRIGERATED', // Europe specific
  DF = 'DROPDECK_FLAT',
  FV = 'FURNITURE_VAN',
  FDD =	'DBL_DROP_FLAT',
  IC = 'IMDL_CONTAINER',
  IO = 'IMDL_CONTAINER',
  IT = 'IMDL_TRAILER',
  I = 'IMDL',
  C	= 'CONTAINER',
  U	= 'TAUT_LINER',
  Z = 'OTHER',
  H = 'POWER_ONLY',
  PO = 'POWER_ONLY',
  VH = 'POWER_ONLY_VAN',
  FH = 'POWER_ONLY_FLATBED',
  RH = 'POWER_ONLY_REEFER',
  J = 'DRAY',
  VJ = 'DRAY_VAN',
  RJ = 'DRAY_REEFER',
  OW = 'OCEAN',
  OD = 'HEAVY_WEIGHT_AIR',
  // D = 'DOMESTIC_AIR',

  // enum values
  All = 'A',
  Bulk = 'B',
  Container = 'C',
  // DomesticAir = 'D',
  Flatbed = 'F',
  PowerOnly = 'H',
  IMDL = 'I',
  LTL = 'L',
  Refrigerated = 'R',
  StandardTautliner = 'U',
  Box = 'V',
  Other = 'Z'
}

export enum ExtendedEquipmentTypeEU {
  P = 'P',
  IntermodalReefer = 'IR',
  StandardTautliner = 'T',
  StandardTiltTrailer = 'TIL',
  Box = 'BX',
  CarCarrier = 'CAR',
  Coiler = 'COIL',
  DumpTipperTrailer = 'TIP',
  MegaTautliner = 'MT',
  MegaTiltTrailer = 'MTIL',
  ParcelTruck = 'PT',
  RefrigeratedDoubleDropDeck = 'DDF',
  Silo = 'SIL',
  SmallTruck = 'STK',
  Tank = 'TNK',
  TiltCoiler = 'TCOI',
  Tractor = 'TR',
  RainTrainJumbo = 'RD'
}
